import React, {ChangeEvent, useState} from "react";
import {Box, TextField} from "@mui/material";
import {chatId, telegramBotToken} from "../../data/data";
import axios from 'axios';

export const AdvantagesTop = () => {

    const [helperText, setHelperText] = useState('')
    const [email, setEmail] = useState('')

    const handleInputChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {

        setHelperText('');
        setEmail(e.target.value);
    };

    const sendTelegramMessage = () => {

        if (!email.includes('@')) {
            setHelperText('Поле не содержит символ "@"');
        } else {

            setEmail('');

            const newMessage = `Оставлена заявка на сайте: \n email: ${email}`;


            const url = `https://api.telegram.org/bot${telegramBotToken}/sendMessage`;
            const data = {
                chat_id: chatId,
                text: newMessage,
            };

            axios.post(url, data)
                .then((response) => {
                    console.log('Сообщение успешно отправлено');
                })
                .catch((error) => {
                    console.error('Ошибка отправки сообщения:', error);
                });

        }

    };


    return (
        <div className={'advantages'} id={'delivery'}>
            <div className={'advantages_top'}>
                <div className={'payment'}>
                    <h2>Доставка и оплата</h2>
                    <p>Оплата на Р/с по договору. Доставка осуществляется <br/>наемным транспортом или ТК на усмотрение
                        клиента.</p>
                </div>

                <div className={'advantages_top_email'}>
                    <h3>Получить оптовый лист</h3>
                    <p>Оставьте заявку и мы вышлем вам подробный прайс <br/>с ценами на уголь для кальяна из ореховой
                        скурлупы</p>
                    <Box>
                        <TextField
                            label='Email'
                            required
                            variant="standard"
                            value={email}
                            onChange={handleInputChangeEmail}
                            error={!!helperText}
                            helperText={!helperText ? '' : helperText}
                            sx={{width: '80%'}}
                        />
                        <br/>
                        <button type={'submit'} onClick={() => sendTelegramMessage()}>Оставить заявку</button>
                    </Box>

                </div>
            </div>

        </div>

    )
}