import React from "react";
import {AdvantagesTop} from "./Advantages/AdvantagesTop";
import {AdvantagesBottom} from "./Advantages/AdvantagesBottom";

export const Advantages = () => {
    return(
        <>
            <div className={'container'}>
                <AdvantagesTop />
                <AdvantagesBottom />
            </div>

        </>

)
}