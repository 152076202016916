import React from "react";

export const AdvantagesBottom = () => {


    return (
        <div className={'advantages_bottom'} id={'contact'}>
            <div className={'contacts'}>
                <h2>Контакты</h2>
                <p>Офис работает с 8 утра до 8 вечера, <br/>Без выходных.</p>

                <p><b>Адрес:</b> Липецк, ул. 9 мая, д. 144</p>
                <hr/>

                <p><b>Телефон:</b> <a href="tel:+79093437111"> +79093437111</a></p>
                <hr/>

                <p><b>Email:</b> smokyjoy@bk.ru</p>
                <hr/>


            </div>
            <div className={'map'}>
                <div dangerouslySetInnerHTML={{
                    __html:
                        '<iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3Ae0a2bf4868e38b7471a36f28cf5610b4252f0e46380d58923b05fa9656593555&amp;source=constructor" ' +
                        'width="100%" height="300" frameborder="0"></iframe>'
                }}/>
            </div>


        </div>

    )
}