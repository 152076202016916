import React, {ChangeEvent, useState} from "react";
import axios from 'axios';
import {Box, TextField} from "@mui/material";
import {chatId, telegramBotToken} from "../data/data";
import backgrond from '../assets/img/backgrond.png'



export const Content = () => {

    const [helperText, setHelperText] = useState(['', '', ''])
    const [name, setName] = useState('')
    const [number, setNumber] = useState(89)
    const [email, setEmail] = useState('')


    const handleInputChangeName = (e: ChangeEvent<HTMLInputElement>) => {
        const copyHelperText = [...helperText]
        copyHelperText[0] = ''
        setHelperText(copyHelperText);
        setName(e.target.value);
    };

    const handleInputChangeNumber = (e: ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;

        // Проверка на максимальное количество символов (11 символов)
        if (inputValue.length > 11) {
            // Если введено более 11 символов, не выполнять никаких действий
            return;
        }

        // Сброс сообщения справки
        const copyHelperText = [...helperText]
        copyHelperText[1] = '';
        setHelperText(copyHelperText);

        if (/^\d*$/.test(inputValue)) {
            const parsedValue = parseFloat(inputValue);
            if (!isNaN(parsedValue)) {
                setNumber(parsedValue);
            }
        }
    };


    const handleInputChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
        const copyHelperText = [...helperText]
        copyHelperText[2] = ''
        setHelperText(copyHelperText);
        setEmail(e.target.value);
    };


    const sendTelegramMessage = () => {
        let newHelperText = [...helperText];

        if (name.length === 0) {
            newHelperText[0] = 'Поле не может быть пустым';
            setHelperText(newHelperText);
        } else if (number.toString().length !== 11) {
            newHelperText[1] = 'Номер должен содержать 11 символов';
            setHelperText(newHelperText);
        } else if (!email.includes('@')) {
            newHelperText[2] = 'Поле не содержит символ "@"';
            setHelperText(newHelperText);
        } else {
            setName('');
            setNumber(89);
            setEmail('');

            const newMessage = `Оставлена заявка на сайте: \n Имя: ${name} \n Номер телефона: ${number} \n email: ${email}`;


            const url = `https://api.telegram.org/bot${telegramBotToken}/sendMessage`;
            const data = {
                chat_id: chatId,
                text: newMessage,
            };

            axios.post(url, data)
                .then((response) => {
                    console.log('Сообщение успешно отправлено');
                })
                .catch((error) => {
                    console.error('Ошибка отправки сообщения:', error);
                });

        }

    };

    return (
        <div className={'content'}>
            <div style={{backgroundImage: `url(${backgrond})`}} className={'container content_center'}>
                <div className={'content_text'}>
                    <h1>Экологичный уголь для кальяна из <br/>
                        ореховой скорлупы с доставкой <br/>
                        по России и СНГ</h1>
                    <p>
                        Прямые поставки угля для кальяна от производителя<br/>
                        на территории РФ. <br/>
                        100% органический продукт с высокой жароотдачей.
                    </p>
                </div>

                <Box className={'form'}>
                    <h3>Получить оптовый прайс</h3>
                    <p>Оставьте заявку и мы отправим вам оптовый прайс на продукцию</p>
                    <TextField
                        label='Ваше имя'
                        required
                        variant="standard"
                        value={name}
                        onChange={handleInputChangeName}
                        error={!!helperText[0]}
                        helperText={!helperText[0] ? '' : helperText[0]}
                        sx={{width: '80%'}}
                    />

                    <br/>
                    <TextField
                        label='Номер телефона'
                        required
                        variant="standard"
                        value={number}
                        onChange={handleInputChangeNumber}
                        error={!!helperText[1]}
                        helperText={!helperText[1] ? '' : helperText[1]}
                        sx={{width: '80%', mt: 2}}
                    />

                    <br/>
                    <TextField
                        label='Email'
                        required
                        variant="standard"
                        value={email}
                        onChange={handleInputChangeEmail}
                        error={!!helperText[2]}
                        helperText={!helperText[2] ? '' : helperText[2]}
                        sx={{width: '80%', mt: 2}}
                    />
                    <br/>

                    <button type={'submit'} onClick={() => sendTelegramMessage()} style={{marginTop: 20}}>
                        Отправить
                    </button>

                </Box>

            </div>


        </div>
    )
}