import React from "react";

import eco from '../assets/img/icon/ecology.png'
import pack from '../assets/img/icon/packaging.png'
import form from '../assets/img/icon/form.png'
import fire from '../assets/img/icon/fire.png'
import smells from '../assets/img/icon/smells.png'
import factory from '../assets/img/icon_top/factory.png'
import quality from '../assets/img/icon_top/quality.png'
import volumes from '../assets/img/icon_top/volumes.png'

import {data} from "../data/data";


export const Catalog = () => {
    return (
        <div className={'catalog'}>
            <div className={'container'} id={'advantages'}>
                <h2>Уголь для кальяна SMOKYJOY - ЭТО:</h2>
                <p>Уголь, являющийся 100% органическим продуктом без токсичных соединений. <br/>
                    Наша команда квалифицированных сотрудников долго занималась разработкой для кальяна, который будет
                    альтернативой кокосовому без
                    <br/>потери качества, сохранив при этом все свои преимущества.
                    <br/>В результате был рожден новый продукт Smokyjoy.
                </p>

                <div className={'icon_top'}>
                    <div>
                        <img src={`${factory}`} alt="" />
                        <p>Собственное производство</p>
                    </div>
                    <div style={{marginLeft: 70}}>
                        <img src={`${quality}`} alt=""  />
                        <p>В центральной части России, в г. Липецк</p>
                    </div>
                    <div style={{marginLeft: 70}}>
                        <img src={`${volumes}`} alt=""/>
                        <p>Любые объемы
                        </p>
                    </div>


                </div>



            </div>



            <div className={"catalog_production"} id={'catalog'}>
                <h1>Каталог продукции</h1>

                {data.map((item, index) => {
                    if (index % 2 === 0) {
                        // Отображаем элементы в парах
                        return (
                            <div className={'container catalog_image '} key={index}>
                                <div className="container catalog_image_cart">
                                    <img src={`${item.img}`} alt=""/>
                                    <p>{item.title}</p>
                                </div>
                                {data[index + 1] && ( // Проверяем, есть ли следующий элемент в массиве
                                    <div className="container catalog_image_cart">
                                        <img src={`${data[index + 1].img}`} alt=""/>
                                        <p>{data[index + 1].title}</p>
                                    </div>
                                )}
                            </div>
                        );
                    } else {
                        return null;
                    }
                })}
            </div>

            <div className={'container'}>


                <div className={'advantages_icon'}>
                    <div>
                        <img src={`${eco}`} alt=""/>
                        <p>Экологически чистый продукт</p>
                    </div>
                    <div>
                        <img src={`${pack}`} alt=""/>
                        <p>Удобные варианты фасовки</p>
                    </div>
                    <div>
                        <img src={`${form}`} alt=""/>
                        <p>Идеальная форма и плотность</p>
                    </div>

                    <div>
                        <img src={`${fire}`} alt=""/>
                        <p>Отличная жароотдача</p>
                    </div>

                    <div>
                        <img src={`${smells}`} alt=""/>
                        <p>Отсутствие запахов</p>
                    </div>

                </div>
            </div>
        </div>
    )
}