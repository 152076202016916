import catalog1 from '../assets/img/catalog1.png'
import catalog2 from '../assets/img/catalog2.png'

export const data = [
    {
        img: catalog1,
        title: 'Zip-пакет 1кг.'
    },
    {
        img: catalog2,
        title: 'Шоу бокс 36 стиков по 6 шт. (216 шт.)'
    },
]

export const telegramBotToken = '6843723387:AAHVQgRBlo24cHXKrg5MhIU25rOK3knvAuc';
export const chatId = "1107384009";